$primary: #99CC00;
$secondary: #003366;
$info: #6c757d;
//$success: #1EB236;

$font-family: Roboto;
$font-weight-base: 300;
$body-color: $secondary;

// $btn-border-radius: 2rem;

@import "node_modules/bootstrap/scss/bootstrap";

.mb-2.required .form-label:after {
  content: "*";
  color: red;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
}


.nav-link.active {
  text-decoration: underline;
}

.clickableIcon {
  &:hover {
    cursor: pointer;
    transform: scale(1.2);
  }
}

.changeIcon {
  &:hover {
    cursor: pointer;
  }
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.language-div {
  width: 100%;
  position: absolute;
  z-index: 2;
}


.boatTile.bg-danger {
  &:hover {
    background: lighten(rgb(220, 53, 69), 6%) !important;
    cursor: pointer;
  }

  &:active {
    background: rgb(220, 53, 69) !important;
    box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.5) !important;
  }
}

.boatTile.bg-secondary {
  &:hover {
    background: lighten(rgb(0, 51, 102), 6%) !important;
    cursor: pointer;
  }

  &:active {
    background: rgb(0, 51, 102) !important;
    box-shadow: inset 0 0 8px rgba(255, 255, 255, 0.5) !important;
  }
}

.boatTile.bg-info {
  &:hover {
    background: lighten($info, 6%) !important;
    cursor: pointer;
  }

  &:active {
    background: $info !important;
    box-shadow: inset 0 0 8px rgba(255, 255, 255, 0.5) !important;
  }
}

.boatTile.bg-success {
  &:hover {
    background: lighten($success, 6%) !important;
    cursor: pointer;

  }

  &:active {
    background: $success !important;
    box-shadow: inset 0 0 8px rgba(255, 255, 255, 0.5) !important;
  }
}

table td {
  white-space: nowrap;

  span {
    cursor: pointer;
    text-decoration: underline;
  }
}

.table-text-field {
  white-space: normal;
  overflow-wrap: anywhere;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: self-end;
  overflow: visible;
  white-space: nowrap;
}

table th {
  position: relative;
  white-space: nowrap;

  span {
    cursor: pointer;
  }
}

table td:last-child {
  width: 2%;
}

table a {
  color: $secondary;
}

.piechart {
  flex-grow: 1;
}

.calendar {
  flex-grow: 1;
}

.tableSearch {
  padding: 7px;

  .searchIcon {
    cursor: pointer;
  }

  .popover {
    // Gods Sake, this partly overwrites a Bootstrap popover class.
    border-color: $secondary;
    left: 100%;
    overflow: hidden;
    position: absolute;
    top: 100%;
    -webkit-transform: translate(-100%);
    transform: translate(-100%);
    width: 200px;
    z-index: 3;
  }

  .filterItem {
    display: flex;
    padding: 4px 2px;
    place-items: center;
    user-select: none;

    &:hover {
      background: lightblue;
    }

    .filterCheck {
      margin-right: 4px;
    }
  }

}

.tableSearch.active {
  background: $secondary;
}

.table-responsive {
  min-height: 200px;
}

.kpi {
  width: 100%;
  font-size: 18px;
  vertical-align: center;
  display: flex;

  b {
    font-size: 20px;
    width: 50%;
    margin-left: 15%;
  }

  span {
    width: 50%;
    display: block;
  }

}

.btn-custom {
  padding: 1.5rem 3rem;
  font-size: 1.5rem;
}

.menuNoWrap {
  white-space: nowrap;
}